<template>
  <div class="user-profile-overview">
    <v-row>
      <v-col
        cols="12"
        class="mb-4 md-mb-6"
      >
        <stats
          :platform="platform"
          :user-data="userData"
          :overview-data="overviewData"
        />
      </v-col>

      <v-col
        v-if="userData.data.user_profile.stat_history"
        cols="12"
        lg="6"
      >
        <audience-growth
          :platform="platform"
          :user-data="userData"
        />
      </v-col>

      <v-col
        v-if="userData.data.user_profile.relevant_tags"
        cols="12"
        lg="6"
      >
        <word-cloud
          :platform="platform"
          :user-data="userData"
        />
      </v-col>

      <v-col
        v-if="userData.data.user_profile.similar_users"
        cols="12"
        lg="6"
      >
        <influencer-lookalikes
          :platform="platform"
          :user-data="userData"
          class="contain-height"
        />
      </v-col>

      <v-col
        v-if="audienceData.followers.lookalike && audienceData.followers.lookalike.length"
        cols="12"
        lg="6"
      >
        <audience-lookalikes
          :items="audienceData.followers.lookalike"
          class="contain-height"
        />
      </v-col>

      <v-col
        v-if="userData.data.user_profile.brand_affinity"
        cols="12"
        lg="6"
      >
        <influencer-brand-affinity
          :platform="platform"
          :user-data="userData"
          class="contain-height"
        />
      </v-col>

      <v-col
        v-if="userData.data.user_profile.interests"
        cols="12"
        lg="6"
      >
        <influencer-interests
          :platform="platform"
          :user-data="userData"
          class="contain-height"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
// Import child components
const Stats = () => import(/* webpackChunkName: "blocks-overview-stats" */ "@/blocks/user-profile/overview/Stats.vue")
const WordCloud = () => import(/* webpackChunkName: "blocks-overview-word-cloud" */ "@/blocks/user-profile/overview/WordCloud.vue")
const AudienceGrowth = () => import(/* webpackChunkName: "blocks-overview-audience-growth" */ "@/blocks/user-profile/overview/AudienceGrowth.vue")
const AudienceLookalikes = () => import(/* webpackChunkName: "blocks-overview-audience-lookalikes" */ "@/blocks/user-profile/overview/AudienceLookalikes.vue")
const InfluencerInterests = () => import(/* webpackChunkName: "blocks-overview-influencer-interests" */ "@/blocks/user-profile/overview/InfluencerInterests.vue")
const InfluencerLookalikes = () => import(/* webpackChunkName: "blocks-overview-influencer-lookalikes" */ "@/blocks/user-profile/overview/InfluencerLookalikes.vue")
const InfluencerBrandAffinity = () => import(/* webpackChunkName: "blocks-overview-influencer-brand-affinity" */ "@/blocks/user-profile/overview/InfluencerBrandAffinity.vue")

// Export the SFC
export default {
  // Name of the component
  name: "Overview",

  // Accept incoming data from parent
  props: {
    platform: {
      type: String,
      required: true,
    },

    userData: {
      type: Object,
      required: true
    },

    overviewData: {
      type: Object,
      required: true
    },

    audienceData: {
      type: Object,
      required: true
    },
  },

  // Register the components
  components: {
    Stats,
    WordCloud,
    AudienceGrowth,
    AudienceLookalikes,
    InfluencerInterests,
    InfluencerLookalikes,
    InfluencerBrandAffinity
  }
}
</script>
